






































import { Component, Vue } from "vue-property-decorator";

import PanelGroup from "@/components/PanelGroup.vue";
import MainBannerTable from "./components/BannerTable.vue";
import MainBannerEdit from "./components/MainBannerEdit.vue";

import { ITabModel, IPanelData } from "@/data/commonModel";
import {
  IMainBannerModel,
  IBannerCountRes,
  IBannerListQuery,
} from "./data/bannerModel";
import { UserModule } from "@/store/modules/user";
import { BannerModule } from "@/store/modules/banner";

// 装饰器模式
@Component({
  components: {
    PanelGroup,
    MainBannerTable,
    MainBannerEdit,
  },
})
export default class extends Vue {
  // Data
  editDialogVisible: boolean = false;
  isOrdering: boolean = false; // 是否在排序中
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "",show:true };
  bannerModel: IMainBannerModel | null = null;

  // Get
  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${BannerModule.mainBannerCountRes.allCount})`,
        key: "all",
      },
      {
        title: `已发布(${BannerModule.mainBannerCountRes.publishedCount})`,
        key: "published",
      },
      {
        title: `未发布(${BannerModule.mainBannerCountRes.unpublishedCount})`,
        key: "unpublished",
      },
    ];
    return arr;
  }
  // Methods
  mounted() {
    BannerModule.FetchMainBannerCountByStatus();
  }

  // 切换选项卡
  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
    this.isOrdering = false;
  }

  // 新建活动
  _onClickAdd() {
    this.editType = "add";
    this.bannerModel = null;
    this.editDialogVisible = true;
  }

  // 开始/取消对MP展示进行排序
  _onClickSort() {
    this.isOrdering = !this.isOrdering;
  }

  _handleEditBannerSuccess(type: string) {
    BannerModule.FetchMainBannerCountByStatus();
    const ref: MainBannerTable = this.$refs[
      "main-banner-table"
    ] as MainBannerTable;
    ref.refreshTable();
  }

  _handleEditBanner(value: IMainBannerModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.bannerModel = JSON.parse(JSON.stringify(value));
  }
}
