import { render, staticRenderFns } from "./MainBannerList.vue?vue&type=template&id=435510df&scoped=true&"
import script from "./MainBannerList.vue?vue&type=script&lang=ts&"
export * from "./MainBannerList.vue?vue&type=script&lang=ts&"
import style0 from "./MainBannerList.vue?vue&type=style&index=0&id=435510df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435510df",
  null
  
)

export default component.exports