



























































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImgUpload from "@components/ImgUpload.vue";
import PopupPreviewVideo from "@components/PopupPreviewVideo.vue";
import { Form as ElForm } from "element-ui";

import { IMediaModel } from "@/data/commonModel";
import {
  importExternalVideo,
  addMainBanner,
  updateMainBanner,
} from "@/remote/banner";
import { getVideo } from "@/remote/video";
import { IMainBannerReqModel, IMainBannerModel } from "../data/bannerModel";

interface IBannerFormModel {
  type: string;
  title: string;
  appId: string;
  subTitle: string;
  redirectLink: string;
  coverImages: IMediaModel[];
  bannerImages: IMediaModel[];
  videoBgImages: IMediaModel[];
  videoId: string;
}

// 装饰器模式
@Component({
  components: {
    PopupPreviewVideo,
    ImgUpload,
    ElForm,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "add",
  })
  editType!: string;
  // editType = 'edit'时所需要的数据
  @Prop() bannerModel!: IMainBannerModel;

  // Data
  formLabelWidth: string = "140px";
  formValueWidth: number = 450;
  formParams: IBannerFormModel = {
    type: "image",
    title: "",
    appId: "",
    subTitle: "",
    redirectLink: "",
    coverImages: [],
    bannerImages: [],
    videoBgImages: [],
    videoId: "",
  };

  bannerId: string = "";
  saveBtnLoading: boolean = false;
  titleLength: number = 0;
  subTitleLength: number = 0;
  videoMaxSize: number = 1024 * 49;
  previewDialogVisible: boolean = false;

  // Rules
  rules = {
    bannerImages: {
      type: "array",
      required: true,
      trigger: "focus",
      message: "请选择Banner图片",
    },
    // coverImages: {
    //   type: "array",
    //   required: true,
    //   trigger: "focus",
    //   message: "请选择视频封面图片",
    // },
    // videoBgImages: {
    //   type: "array",
    //   required: true,
    //   trigger: "focus",
    //   message: "请选择视频背景图片",
    // },
    videoId: {
      type: "string",
      required: true,
      trigger: "focus",
      message: "请输入视频Id",
    },
    appId: {
      type: "string",
      required: true,
      trigger: "blur",
      message: "请输入要跳转的小程序appId",
    },
  };

  // Computed
  get uploadImageItemWidth() {
    return `${(this.formValueWidth - 20) / 5}px`;
  }

  // Watch
  @Watch("bannerModel", {
    immediate: true,
    deep: true,
  })
  onBannerModelChange(value: IMainBannerModel) {
    if (!value) return;
    // console.log("onBannerModelChange");
    // console.log(value);
    const model = JSON.parse(JSON.stringify(value));
    this.bannerId = model.id;

    this.formParams = {
      type: model.type === 0 ? "image" : model.type === 1 ? "video" : "appId",
      title: model.title,
      subTitle: model.subtitle,
      appId: model.appId,
      redirectLink: model.redirectLink,
      coverImages: model.type === 1 ? [model.videoCoverImage] : [],
      bannerImages:
        model.type === 0 || model.type === 2 ? [model.imageCoverImage] : [],
      videoBgImages: model.type === 1 ? [model.videoBackgroundImage] : [],
      videoId: model.type === 1 ? model.video.externalVideoId : "",
    };
    this.subTitleLength = this._calcTextLength(this.formParams.subTitle);
    this.titleLength = this._calcTextLength(this.formParams.title);
  }

  @Watch("formParams", {
    immediate: true,
    deep: true,
  })
  onFormParamsChange(value: IBannerFormModel) {
    const { coverImages, bannerImages, videoBgImages, videoId } = value;
    const form = this.$refs["bannerForm"] as ElForm;
    if (form && coverImages.length > 0) {
      // form.validateField('coverImages')
    }
    if (form && bannerImages.length > 0) {
      form.validateField("bannerImages");
    }
    if (form && videoBgImages.length > 0) {
      form.validateField("videoBgImages");
    }
    if (form && videoId.length > 0) {
      form.validateField("videoId");
    }
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }
  videoUrl: string = "";
  async _previewVideo() {
    const res = await getVideo(this.formParams.videoId);
    if (res?.data?.data?.url) {
      this.videoUrl = res.data.data.url;
      this.previewDialogVisible = true;
    } else {
      this.$message.error("请确认视频id是否正确");
      return;
    }
    console.log(res);
  }
  _onInput(event: any, type: string) {
    var value = event.target.value.trimStart();
    var temp = 0;
    var maxLen = type === "subTitle" ? 172 : 62;

    if (value.length === 0 && type === "title") {
      this.titleLength = 0;
    } else if (value.length === 0 && type === "subTitle") {
      this.subTitleLength = 0;
    }

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        // @ts-ignore
        value = value.substr(0, i).trimStart();
      }
    }
    if (type === "title") {
      this.formParams.title = value;
      this.titleLength = temp > maxLen ? maxLen : temp;
    } else {
      this.formParams.subTitle = value;
      this.subTitleLength = temp > maxLen ? maxLen : temp;
    }
  }

  _calcTextLength(value: string): number {
    let temp = 0;
    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
    }
    return temp;
  }

  _onClickConfirm() {
    const form = this.$refs["bannerForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit"
        ? "确认更新该Banner吗？"
        : "确认保存为新的Banner吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    // const params = JSON.parse(JSON.stringify(this.formParams));

    const formParams = this.formParams;
    const reqModel: IMainBannerReqModel = {
      type:
        formParams.type === "image" ? 0 : formParams.type === "appId" ? 2 : 1,
      title: formParams.title,
      subtitle: formParams.subTitle,
      appId: formParams.appId,
      redirectLink: formParams.redirectLink,
      imageCoverImageId:
        formParams.type === "image" || formParams.type === "appId"
          ? formParams.bannerImages && formParams.bannerImages[0].id
          : undefined,
      // videoCoverImageId:
      //   formParams.type === "video"
      //     ? formParams.coverImages && formParams.coverImages[0].id
      //     : undefined,
      // videoBackgroundImageId:
      //   formParams.type === "video"
      //     ? formParams.videoBgImages && formParams.videoBgImages[0].id
      //     : undefined,
      videoId: formParams.type === "video" ? formParams.videoId : undefined,
    };

    this.saveBtnLoading = true;

    if (this.editType === "edit") {
      this._updateBanner(reqModel);
    } else {
      this._createBanner(reqModel);
    }
  }

  // 新增
  async _createBanner(reqModel: IMainBannerReqModel) {
    try {
      const videoRes = await importExternalVideo(reqModel.videoId || "");
      if (videoRes.status !== 200) {
        this.$message.error("视频上传出错，请重试。");
        return;
      }

      reqModel.videoId = videoRes.data.data.id;
      const res = await addMainBanner(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditBannerSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_createBanner出错了");
    }
  }

  // 编辑
  async _updateBanner(reqModel: IMainBannerReqModel) {
    try {
      const videoRes = await importExternalVideo(reqModel.videoId || "");
      if (videoRes.status !== 200) {
        this.$message.error("视频上传出错，请重试。");
        return;
      }

      reqModel.videoId = videoRes.data.data.id;
      const res = await updateMainBanner(this.bannerId, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditBannerSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateBanner 出错了");
    }
  }
}
